import React from 'react'

import "./Listado.scss"

import more from "../assets/more_vert-black-18dp.svg"
import cerrar from "../assets/close-24px.svg"
import Iconos from "../ClasesFunsiones/Iconos"
import Estudio from '../ClasesFunsiones/Estudio'
import AppDevice from '../../../Class/device'

function Listado(props) {
    let array = props.ListaEstudios.items
    if(props.ListaEstudios.cargando){
        return(
            <div id="Listado">
                <Cargando/>
            </div>
        )
    }
    if(props.ListaEstudios.error){
        return(
            <div id="Listado">
                Error de conexion con el servidor
            </div>
        )
    }
    let items = []
    for (let index = 0; index < array.length; index++) {
        const element = array[index];
        if(element.filtrar(props.FiltroPaciente) && element.filtrarmod(props.filtromod)){
            items.push(
              <Item key={index} herramientas={element.ID===props.VerHerramientas.ID} estudio={element} {...props}/>
            )
        }
    }
    return (
        <div id="Listado">
            {items}
        </div>
    )
}

export default Listado
///////////////////////////////////////
const Item = (props)=>{
    let clase = props.herramientas?"Item activo":"Item"
    return(
        <div className={clase}>
            <div className="DatosEstudio">
                <DatosEstudio {...props}/>
            </div>
            <div className="Herramientas">
                <Herramientas {...props}/>
            </div>
        </div>
    )
}
const DatosEstudio = (props)=>{
    let inform = "Inform"
    if (props.estudio.INFORM !== ""){
      inform = "Inform Exist"
    }
    let abrirherramientas = ()=>{
        props.AbrirHerramientas(props.estudio)
    }
    let verestudio = ()=>{
        let a = {RangoFechasSt:props.fechas,FiltroModalidades:props.filtromod}
        props.estudio.Abririmagenes(a)
        props.history.push(`/visor/${props.estudio.ID}`)
    }
    return (
      <div className="DatosEstudio1">
       <div className={inform}></div>
        <div className="DatosEstudio11">
          <IconoSexo {...props} />
        </div>
        <div className="DatosEstudio12" onClick={verestudio}>
          <div className="DatosEstudio121">
            {`${props.estudio.getnombre()} - (${props.estudio.calcage()})`}
          </div>
          <div className="DatosEstudio122">
            {props.estudio.getmodalidad()}
          </div>
          <div className="DatosEstudio123">{props.estudio.getfecha()}</div>
        </div>
        <div className="DatosEstudio13" onClick={abrirherramientas}>
          <img src={more} alt="" width="40px" />
        </div>
      </div>
    );
}
const Herramientas = (props)=>{
    let cerrarherramientas = ()=>{
        props.AbrirHerramientas({})
    }
    let abrirDialog = (e)=>{
      props.onDialog(e)
    }
    let DownloadStudy = ()=>{
      let url = `${AppDevice.ServerUrl}/DescargaEstudio/${props.estudio.EST_UID}`
      window.open(url,'_blank')
    }
    return(
        <div className="Herramientas1">
            <div className="Herramientas11">
              <div className="Herramientas111">{props.estudio.getnombre()}</div>
              <div className="Herramientas112">
                <IconoHerramientas icon="Share" onClick={abrirDialog.bind(this,0)}/>
                <IconoHerramientas icon="upload" onClick={abrirDialog.bind(this,1)}/>
                <IconoHerramientas icon="broadcast" onClick={abrirDialog.bind(this,2)}/>
                <IconoHerramientas icon="download" onClick={DownloadStudy}/>
              </div>
            </div>
            <div className="Herramientas12" onClick={cerrarherramientas}>
                <img src={cerrar} alt="" width="40px"/>
            </div>
        </div>
    )
}
const IconoHerramientas = (props)=>{
  return(
    <div className="IconoHerramientas" onClick={props.onClick}>
        <Iconos icon={props.icon}/>
    </div>
  )
}
const IconoSexo = (props) => {
  return (
    <div style={{boxSizing:"border-box",padding:"6px"}}>
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 24 24"
      >
        <path
          fill={props.estudio.colorsexo()}
          d="M13.7,2.1C6.9,1,1,6.9,2.1,13.7c0.7,4.1,4,7.5,8.2,8.2C17.1,23,23,17.1,21.9,10.3
        C21.2,6.2,17.8,2.8,13.7,2.1z M11.4,5.1c2.1-0.4,3.9,1.4,3.5,3.5c-0.2,1.2-1.2,2.1-2.4,2.4c-2.1,0.4-3.9-1.4-3.5-3.5
        C9.3,6.2,10.2,5.3,11.4,5.1z M12,19.2c-2.5,0-4.7-1.3-6-3.2c0-2,4-3.1,6-3.1c2,0,6,1.1,6,3.1C16.7,17.9,14.5,19.2,12,19.2z"
        />
      </svg>
    </div>
  );
};
///////////////////////////////////////
const Cargando = ()=>{
    return(
        <div>Cargando</div>
    )
}

