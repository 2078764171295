import React from 'react'

function Caducado() {
    let conf = window.localStorage.getItem('ConfiguracionGeneral')
    conf = JSON.parse(conf)
    return (
        <div className='Caducado'>
            <div className='Logocaducado'>
                <img src='./IMG/warning.png'/>
            </div>
            <div className='mensaje'>
                El enlace ha caducado por favor pogase en contacto con el numero proveedor del estudio.
            </div>
        </div>
    )
}

export default Caducado
