import axios from 'axios';


class AppDevice {
    static ServerUrl = '';
    static ExternalServer = '';
    static InstitutionName = '';
    static sharetime = 0;
    static phoneRef = '';
    static DefaultCountry = "";
    static WPM = "";
    static CompanyInfo = {
        name:"",
        address:"",
        phone:"",
        website:""
    };
    static async DeviceInit(){
        let config = (await axios.get('/configuracion.json')).data;
        console.log(config);
        let wpm = (await axios.get('/wp.txt')).data;
        this.WPM = wpm;
        this.ServerUrl = config.ServidorDicom;
        this.ExternalServer = config.ServicorExterno;
        this.InstitutionName = config.NombreInstitucion;
        this.sharetime = config.sharetime;
        this.phoneRef = config.ref;
        this.DefaultCountry = config.countryCode?config.countryCode:"591";
        this.CompanyInfo = {
            name:config.CompanyInfo.name,
            address:config.CompanyInfo.address,
            phone:config.CompanyInfo.phone,
            website:config.CompanyInfo.website
        };
    }
    static toJson(){
        return {
            ServidorDicom:this.ServerUrl,
            NombreInstitucion:this.InstitutionName,
            ServicorExterno:this.ExternalServer,
            sharetime:this.sharetime,
            ref:this.phoneRef,
            countryCode:this.DefaultCountry,
            CompanyInfo:this.CompanyInfo,
            WPM:this.WPM
        }
    }
    static isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
}

export default AppDevice;