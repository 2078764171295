import React,{lazy,Suspense} from 'react'
import Cargando from '../Externo/Cargando';
const Visor = lazy(() => import('../NuevoVisor/Visor'));

function Local(props) {
  console.log('Local',props)
  return (
    <Suspense fallback={<Cargando mensaje="Inicializando Visor"/>}>
    <Visor {...props}/>
    </Suspense>
  )
}

export default Local