import React, { useEffect, useState } from 'react'

import Consulta from "../ClasesFunsiones/consultas"

const Upload = (props)=>{
    const [Informe, setInforme] = useState({listo:false,blob:{}})
    const [mensajeBoton,setmensajeBoton] = useState("Cargar Informe")
    let CargarInformeClick = ()=>{
        document.getElementById("Informeupload").click()
    }
    let cargararchivo = (e)=>{
        setInforme({listo:true,blob:e.target.files[0]})
    }
    let drag = (e)=>{
        e.preventDefault()
        e.stopPropagation()
    }
    let drop = (e)=>{
        e.preventDefault()
        e.stopPropagation()
        if(e.dataTransfer.files[0].type === "application/pdf"){
            setInforme({listo:true,blob:e.dataTransfer.files[0]})
        }else{
            alert("El archivo no es PDF")
        }
    }
    let send = ()=>{
        setInforme({...Informe,listo:false})
        setmensajeBoton("Cargando...")
        props.paciente.uploadinform(Informe.blob)
        .then((res)=>{
            setInforme({listo:false,blob:{}})
            setmensajeBoton("Cargar Informe")
        })
        .catch((e)=>{
            console.log(e)
        })
    }
    useEffect(() => {
        
        return () => {
            setInforme({listo:false,blob:{}})
        }
    }, [])
    return (
      <div className="Upload">
        <div className="Upload1">
          <div className="Upload11" onClick={CargarInformeClick} onDragOver={drag} onDrop={drop}>
              {Informe.blob.name===undefined?`Arrastrar o click para cargar el informe`:Informe.blob.name}
          </div>
          <input type="file" id="Informeupload" onChange={cargararchivo} accept="application/pdf"/>
        </div>
        <div className="Upload2">
            <button disabled={!Informe.listo} className="Boton" value={Informe.blob} onClick={send}>{mensajeBoton}</button>
        </div>
      </div>
    );
}

export default Upload