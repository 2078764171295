const ShareCountries = [
    {
        name:"Bolivia",
        code:"591"
    },
    {
        name:"Paraguay",
        code:"595"
    },
    {
        name:"Estados Unidos",
        code:"1"
    },
    {
        name:"Venezuela",
        code:"58"
    },
]

export default ShareCountries